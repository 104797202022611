@import '../../styles/variables.scss';

.chat-container {
  position: relative;
  z-index: 10;

  iframe {
    height: calc(100vh - 64px - 32px - 28px);
    overflow: hidden;
    border: 0;
  }

  .chat-window-hidden {
    width: 0;
    height: 0;
    padding: 0;
    display: none;
  }

  .chat-window {
    top: 0;
    right: 0;
    width: min(calc(100vw), 428px);

    ul {
      list-style: circle;
      margin-bottom: 0 !important;

      li {
        margin-left: 40px;
      }
    }

    .spinnerModal {
      position: absolute;
      display: flex;
      justify-content: center;
      align-content: center;
      width: 100%;
      height: 100%;
      cursor: default;
      z-index: 99999999999;
      background: rgba(0, 0, 0, 0.2);

      .errorMessage {
        display: flex;
        align-items: center;
        color: $warning-red;

        & > span {
          padding-left: 0.5em;
        }
      }
    }

    .chat-box {
      padding: 1em 0 1em 1em;

      /* adjust as needed */

      border-left: 1px solid #ccc;
      background: #fff;

      .webchat__basic-transcript {
        background: linear-gradient(90deg, rgba(134, 97, 197, 0.05) 2.12%, rgba(0, 120, 212, 0.05) 92.39%), #fff !important;
      }

      .chat-header {
        display: flex;
        justify-content: space-between;
        margin: 5px 10px 0 10px;

        .chat-header-title {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #242424;
          display: flex;

          .copilotIcon {
            margin-right: 4px;
          }
        }

        .chatHeaderClose {
          padding: 0;
          border: none;
          background: none;
          min-width: 10px;
        }
      }
    }

    .chat-box-copilot-2 {
      padding: 1em 0;
    }
  }

  .webchat-container,
  .webchat-container > * {
    // 70px page header, 32px chat header, 28px padding
    height: calc(100vh - 70px - 32px - 28px);
    width: 100%;

    .webchat__send-box__main {
      border-radius: 8px;
      border: #e6e6e6;
      border-style: solid;
    }

    .webchat__send-box__button {
      margin: 6px;
    }

    @media (forced-colors: active) {
      .webchat__send-icon {
        fill: currentColor;
      }
    }

    .ac-actionSet {
      flex-wrap: wrap;
      width: 100% !important;
      flex-direction: inherit !important;

      .ac-pushButton {
        margin: 1px !important;
        border-radius: 4px;
        padding: 6px;
        font-weight: 400;
        cursor: pointer;
        font-family: 'Calibri', 'Helvetica Neue', 'Arial', sans-serif, 'FabricMDL2Icons-12';
      }

      .ac-pushButton:not(:last-child) {
        margin-right: 8px !important;
      }

      .ac-pushButton:first-child {
        background: linear-gradient(90deg, #185abd 10%, #008296 100%);
        color: #fff;
        border-color: inherit;
      }

      .ac-pushButton:not(:first-child) {
        background: #fff;
        color: #000;
        border-color: #0078d7;
      }

      div[style*='height: 8px'] {
        display: none;
      }
    }
  }

  .webchatSendBox {
    bottom: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #ccc;
  }

  .webchat__send-box__main {
    height: 5.44em;
    position: relative;
  }

  .webchat__send-box__button {
    bottom: 0;
    position: ABSOLUTE !important;
    transform: rotate(68deg);
  }

  .webchat__send-button {
    position: absolute !important;
    bottom: 0 !important;
    right: 0 !important;
    transform: rotate(0deg);
  }
}

@media (max-width: 768px) {
  .chat-container-hidden {
    display: none;
  }
}

.iframe-full-height {
  height: calc(100vh - 32px - 28px) !important;
}

.webchat-container-full-height {
  height: calc(100vh - 32px - 28px) !important;
}
