@import './styles/variables.scss';

html {
  font-size: 100%;
}

body {
  padding: 0;
  margin: 0;
  background-color: $beige-light;
  min-width: 280px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .appWrapper {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;
    .app {
      display: flex;
      flex-direction: column;
      overflow: auto;
      width: 100%;
      justify-content: space-between;
    }
  }
}

.stepContainer {
  margin: 0 auto 40px;
  padding: 20px 40px;
  background-color: $white;
  border: 1px solid $border-gray;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  &:last-child {
    margin-bottom: 0;
  }
}

.transparentContainer {
  margin: 0 auto 40px;
  padding: 0 40px 20px;
  max-width: 850px;
}

hr {
  height: 1px;
  border-width: 0;
  background-color: #d6d6d6;
}

.ms-Dialog-actionsRight {
  .ms-Dialog-action {
    margin: 0 4px;
  }
}

.ms-Checkbox,
.ms-CheckBox-input {
  input {
    .ms-Fabric--isFocusVisible &:focus + label::before {
      @media screen and (-ms-high-contrast: active) {
        outline: $border-black;
      }
    }
  }
}

.required {
  color: #b40000;
  font-style: italic;
  text-align: right;
}
