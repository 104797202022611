.copilotButton {
  margin-left: 8px;
  padding: 1px 4px;
  min-width: 0;
  background: none;
  border: 0;
  &:hover {
    background: none;
  }
  &:focus {
    outline: 1px solid #fff;
  }
}

.copilotButton:hover .copilotIcon {
  transition: transform 250ms cubic-bezier(0.33, 0, 0, 1);
  transform: scale(1.1);
}
