@import '../../styles/variables.scss';
@import '../../localeStyle.scss';

.ms-Layer {
  .SessionTimeout {
    .hideScreenReader {
      display: none;
    }

    .privacyMessage {
      text-align: left;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0;
      color: #000;
      margin-bottom: 2em;
    }

    .ms-Overlay {
      background: #000 0% 0% no-repeat padding-box !important;
      opacity: 0.75 !important;
    }

    .ms-Dialog-main {
      max-width: 60em !important;
      overflow: inherit;

      @media screen and (max-width: 768px) {
        width: 90% !important;
      }

      .ms-Modal-scrollableContent {
        .ms-Dialog-header {
          .ms-Dialog-title {
            padding: 0 0 0 1.75em;
            font-size: 17px;
            font-weight: 700;
          }
        }

        .ms-Dialog-inner {
          font-size: 15px;
          padding: 20px 0 0 0;

          @media only screen and (min-width: 426px) and (max-width: 768px) {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }

          .ms-Dialog-content {
            width: 90%;
            text-align: left;
            margin: 0 auto;

            .requestInfoList {
              list-style-type: disc;
              margin-top: 0;
              padding-left: 20px !important;

              .requestInfoListItem {
                padding: 0.5em 0;
              }
            }
          }

          .contentDescription {
            text-align: left;
            opacity: 1;
            font-size: small;
            margin-left: 0.3em;
          }

          .divider {
            width: 100%;
            display: flex;
            margin: 20px auto;
          }

          .content {
            text-align: Left;
            opacity: 1;
            margin: -25px auto;

            .stopWatch {
              margin-top: 2em;
              margin-left: 0.5em;
              color: #046dce;
              transform: scale(2.5, 2.5);
            }

            .contentBack {
              color: #000;
              font-size: 19px;
              font-weight: $semibold;
            }

            @media screen and (max-width: 425px) {
              .contentBack {
                font-size: 18px;
              }
            }
          }

          .requestInformationActionsButton {
            padding-top: 4em;
            padding-bottom: 2.5em;
            margin: 0 auto;
            width: 95%;
            float: right;

            @media screen and (max-width: 425px) {
              flex-direction: column-reverse;
              width: 80%;
            }

            @media screen and (max-width: 320px) {
              width: 80%;
              float: right;
              justify-content: end;
            }

            @media screen and (max-width: 768px) {
              width: 120%;
            }

            @media only screen and (min-width: 480px) and (max-width: 767px) {
              width: 140%;
            }

            .requestInformationActionsWrapper {
              display: flex;
              flex-direction: row;
              margin: -30px auto;
              justify-content: end;

              @media screen and (max-width: 425px) {
                display: grid;
                justify-content: center;
                grid-template-columns: 50% 50%;
                margin-left: 3em;

                .cancelButton {
                  width: 80%;
                  margin-left: 1.5em;
                }

                &::after,
                &::before {
                  display: none;
                }

                margin-top: -28px;
              }

              @media only screen and (min-width: 480px) and (max-width: 767px) {
                .cancelButton {
                  width: 80%;
                  margin-left: 1.5em;
                }
              }

              @media screen and (max-width: 320px) {
                display: flex;
                flex-direction: column;
                margin-left: 3.7em;
                width: 80%;
                justify-content: center;
              }
            }

            .ms-Button {
              width: 100%;
              height: 35px;
              border-radius: 3px;
              margin-bottom: 1em;

              &:focus {
                border: 1px solid #000;
              }
            }
          }
        }
      }
    }
  }
}
