@import '../../../styles/variables.scss';

/* This file uses container queries, which are supported by all the
 * browsers that JupyterLab works with, but which stylelint cannot
 * validate.
 */
// Common style for pages
.container {
  /* stylelint-disable */
  container-type: inline-size;
  /* stylelint-enable */
  .burgerMenu {
    height: 25px;
    width: 25px;
    z-index: 200;
    display: none;

    /* stylelint-disable */
    @container (max-width: 1640px) {
      /* stylelint-enable */
      display: flex;
      visibility: visible;
      justify-content: space-around;
      flex-flow: column nowrap;
      margin: 0.5em 0;
    }
    // div {
    //   width: 1.8rem;
    //   height: 2px;
    //   background-color: $white;
    //   border-radius: 10px;
    //   transform-origin: 1px;
    //   transition: all 0.3s linear;
    // }
  }
}
