@import './styles/variables.scss';

/* Button custom styles */
.customButton {
  width: max-content;
  min-width: 8em;
  height: 36px;
  border-radius: 3px;
  margin-bottom: 0.5em;
  padding-left: 1.25em;
  padding-right: 1.25em;

  &:focus {
    border: $border-black;
    border-radius: 3px;
  }
}

/* Button with icon */
.buttonWithIcon {
  min-width: 9em;
  padding: 0.5em;
}

.buttonWithIconRight {
  .ms-Button-label {
    margin-right: 0;
  }

  .ms-Icon {
    margin: 0 0 0.25em 0.5em;
  }
}

.buttonWithIconLeft {
  .ms-Button-label {
    margin-right: 0;
  }

  .ms-Icon {
    margin: 0 0 0.25em 0.25em;
  }
}

/* Button with padding */
.buttonWithPadding {
  padding: 0 1.25em;
}

/* DEFAULT button custom styles */

/* White button with blue font and radius
Practice test, Prerequisites, Join waitlist */
.defaultButtonBlue {
  border: 1px solid $link-text;
  border-radius: 3px;
  background-color: $white;
  color: $link-text;

  &:hover {
    background: none;
    color: $link-text;
  }
}

/* Go to Teams course */
.defaultButtonBlack {
  border: 1px solid $black;
  border-radius: 3px;
  background-color: $white;
  color: $black;

  &:hover {
    background: none;
    color: $black;
  }
}

/* NO BORDER button custom styles */

/* Button with blue text, white background, no underline on hover, and no border - Cancel registration, Clear all, Copy link buttons */
.defaultNoBorderButton {
  min-width: max-content;
  padding-left: 0;
  padding-right: 0;
  margin-top: 0.5em;
  display: inline-block;
  color: $link-text;
  border: none;
  background-color: transparent;

  &:hover {
    background: none;
    color: $link-text;
  }

  &:focus {
    border: $border-black;
    border-radius: 3px;
  }

  @media only screen and (min-width: 712px) and (max-width: 768px) {
    width: max-content;
  }

  @media only screen and (max-width: 480px) {
    margin-top: 0;
  }
}

/* DISABLED button custom styles */

/* Gray text no background - Disabled cancel registration button */
.grayText {
  color: $text-darker-gray;
}

/* Used for buttons within a drawer in deliveries cards - Prerequisites and collapse */
.primaryButtonDisabled {
  background: $primary-blue 0% 0% no-repeat padding-box;
  opacity: 0.25 !important;

  &:focus {
    border: $border-black;
  }
}

/* GREEN button custom styles */

/* Green button - Users are successfully registered or waitlisted */
.successButton {
  color: $text-green-success !important;
  background: #e2f7e1 0% 0% no-repeat padding-box;
  border: none;
}

.successButtonDarkGreen {
  min-width: 10em;
  background-color: $text-green-success !important;
  border: none;
}

/* Blue button custom styles */

.successButtonBlue {
  color: $white !important;
  background: #0078d7 0% 0% no-repeat padding-box;
  border: none;
}
