@import '../../styles/variables.scss';

.footer {
  background-color: #f2f2f2;
  padding-bottom: 1.5em;
  min-height: 100px;
  // min-width: 1215px;
  max-width: 100%;

  .linksContainer {
    overflow: visible;
    width: 100%;
    max-width: 1215px;
    margin: 0 auto;
    margin-top: 43px;

    nav {
      float: right;
    }

    ul {
      padding-left: 0;
      list-style-type: none;
      line-height: 16px;
      margin: 3px 0 3px 0;
      display: inline-block;

      li {
        padding: 0 24px 4px 0;
        display: inline-block;

        a,
        span {
          color: #616161;
          font-size: 11px;
          font-weight: 600;
        }

        a {
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .footerLocalizationLink {
      text-align: left;
      color: #616161;
      font-size: 11px;
      font-weight: 600;

      .localizationIcon {
        font-size: 20px;
        padding-right: 10px;
        padding-left: 20px;
        float: left;
      }

      .localizationText {
        vertical-align: middle;
        float: left;
        padding-top: 4px;
        margin-left: 0;
      }
    }

    span {
      padding: 0 24px 4px 0;
      display: inline-block;
      color: #616161;
      font-size: 11px;
      font-weight: 600;
    }
  }
}

//Mob
@media only screen and (max-width: 425px) {
  .footer {
    background-color: #f2f2f2;
    min-height: 126px;
    min-width: unset;
    max-width: 100%;

    .linksContainer {
      overflow: visible;
      width: 100%;
      max-width: unset;
      margin-bottom: 0;
      margin-top: 30px;
      margin-left: 0;
      margin-right: 0;

      nav {
        float: right;
      }

      ul {
        padding-left: 0;
        list-style-type: none;
        line-height: 16px;
        margin: 3px 0 3px 0;
        display: inline-block;

        li {
          padding: 0 24px 4px 0;
          display: inline-block;
          margin-left: 20px;

          a,
          span {
            color: #616161;
            font-size: 11px;
            font-weight: 600;
          }

          a {
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      span {
        padding: 0 24px 4px 0;
        display: inline-block;
        margin-left: 20px;
        color: #616161;
        font-size: 11px;
        font-weight: 600;
      }
    }
  }
}

//Tab
@media only screen and (min-width: 426px) and (max-width: 768px) {
  .footer {
    background-color: #f2f2f2;
    min-height: 76px;
    min-width: unset;
    max-width: 100%;

    .linksContainer {
      overflow: visible;
      width: 100%;
      max-width: unset;
      margin-bottom: 0;
      margin-top: 30px;
      margin-left: 0;
      margin-right: 0;

      nav {
        float: left;
      }

      ul {
        padding-left: 0;
        list-style-type: none;
        line-height: 16px;
        margin: 3px 0 3px 0;
        display: inline-block;

        li {
          padding: 0 24px 4px 0;
          display: inline-block;
          margin-left: 20px;

          a,
          span {
            color: #616161;
            font-size: 11px;
            font-weight: 600;
          }

          a {
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      span {
        padding: 0 24px 4px 0;
        display: inline-block;
        margin-left: 20px;
        color: #616161;
        font-size: 11px;
        font-weight: 600;
      }
    }
  }
}
