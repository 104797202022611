.backoutBurger {
  display: none;
  @media (max-width: 1024px) {
    background: #000 0% 0% no-repeat padding-box;
    height: 100%;
    width: 100%;
    z-index: 100;
    position: absolute;
    visibility: hidden;
    opacity: 0.8;
    display: flex;
  }
}
