[data-skip-link] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 40px;
  z-index: 10000;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;

  @media only screen and (max-width: 1024px) {
    width: 20% !important;
  }

  &:not(:focus) {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal;
  }

  &:focus {
    outline: 2px dashed #000;
    outline-offset: -2px;
  }
}
