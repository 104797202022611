@import '../../../styles/variables.scss';
.rightNavDialog {
  max-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
  .ms-Dialog-header {
    display: none;
  }
}

.ms-Overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

ul.rightNavmenuopen {
  list-style: none;
  display: none;
  flex-flow: row nowrap;
  z-index: 200;
  @media (max-width: 1024px) {
    flex-flow: column nowrap;
    background-color: $white;
    display: flex;
    visibility: visible;
    position: absolute;
    transform: translateX(0);
    top: 55px;
    right: 0;
    width: 316px;
    opacity: 2;
    padding-top: 0.5rem;
    padding-left: 5px;
    @media (max-width: 330px) {
      width: 310px;
    }
    @media (max-width: 280px) {
      width: 270px;
      right: 0;
    }
    @media only screen and (min-width: 426px) and (max-width: 1024px) {
      right: -10px;
    }
    li {
      color: #000;
      visibility: visible;
      left: 71px;
      background: #fff 0% 0% no-repeat padding-box;
      border-radius: 3px;
      opacity: 1;
      h3 {
        margin-top: 0;
      }
    }
  }
  .rightNavmenuopen-li {
    @media only screen and (min-width: 426px) and (max-width: 1024px) {
      margin-right: 28px;
    }
    @media (max-width: 425px) {
      margin-right: 20px;
    }
  }
}
ul.rightNavmenuClose {
  list-style: none;
  display: none;
  flex-flow: row nowrap;
  z-index: 200;
  @media (max-width: 1024px) {
    flex-flow: column nowrap;
    background-color: $white;
    display: flex;
    visibility: visible;
    position: fixed;
    transform: translateX(100%);
    top: 55px;
    right: 0;
    height: 959px;
    width: 314px;
    opacity: 2;
    padding-top: 3.5rem;
    padding-left: 5px;
    li {
      color: #000;
      visibility: visible;
      border-bottom: 2px solid #f1f1f1;
      left: 71px;
      width: 260px;
      height: 54px;
      background: #fff 0% 0% no-repeat padding-box;
      border-radius: 3px;
      opacity: 1;
      margin-left: 10px;
      h3 {
        margin-top: 0;
      }
    }
  }
}

.ms-Dialog-content {
  position: inherit;
}

.ms-Dialog-inner {
  flex-shrink: 1 !important;
  flex-grow: 1 !important;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
}

.ms-Dialog-footer {
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
  background: linear-gradient(transparent, 5%, #fff);
}

.linkContainer {
  display: flex;
  justify-content: space-between;
}

.welcomeName {
  margin: 15px 5px 15px;
}

.footerLink {
  box-sizing: border-box;
  margin-right: 5px;
  &.active {
    border-bottom: 4px #000 solid;
  }
}
