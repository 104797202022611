@import 'src/styles/variables.scss';

ul.rightNavUl {
  width: 95%;
  margin: 0 auto;
  list-style: none;
  padding-left: 0;
  margin-bottom: 3em;

  li {
    border-bottom: 2px solid #f1f1f1;
    color: #172840 !important;

    a {
      &:hover {
        background-color: $white;
        color: #172840 !important;
      }

      &.active {
        background-color: $white;
        color: #172840 !important;
        display: block;
        border-radius: 3px;
        height: 44px;

        @media (max-width: 280px) {
          width: 230px;
        }

        .Main_LearnYourOwn {
          height: 44px;
          width: 3px;
          background-image: linear-gradient(to right, #008575, #008575 10%);
        }

        .Main_ProductivityWorkshop {
          height: 44px;
          width: 3px;
          background-image: linear-gradient(to right, #008575, #008575 10%);
        }

        .Main_OneDayMSClass {
          height: 44px;
          width: 3px;
          background-image: linear-gradient(to right, $pink-dark, $pink-dark 10%);
        }

        .Main_MultidayMSClass {
          height: 44px;
          width: 3px;
          background-image: linear-gradient(to right, $pink, $pink 10%);
        }

        .Main_FindMoreClasses {
          height: 44px;
          width: 3px;
          background-image: linear-gradient(to right, $black, $black 10%);
        }

        .Main_GetMSCerts {
          height: 44px;
          width: 3px;
          background-image: linear-gradient(to right, $secondary-blue, $secondary-blue 10%);
        }

        .Main_GameLearning {
          height: 44px;
          width: 3px;
          background-image: linear-gradient(to right, #9b0f62, #9b0f62 10%);
        }
      }
    }
  }

  .link {
    color: $link-text !important;
    cursor: pointer !important;

    .active {
      background-color: #172840 !important;
    }

    .hover {
      background-color: #172840 !important;
    }
  }

  .rowArrange {
    background: #f3f6f8 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
  }

  .menuBarContentNav {
    list-style-type: none;
    margin: 3px;

    .ms-Grid {
      padding: 0;
    }
  }

  .searchBarDiv {
    margin-top: 1em;
    margin-bottom: 1em;
    padding-bottom: 2em;

    .cancelAlignment {
      margin-top: 0.5em;
      float: right;
    }
  }

  .navLinkSuccess {
    max-width: 100%;
  }

  .numberedListItem {
    width: fit-content;
    margin-top: 0.65em;
  }

  .menuBarHome {
    padding: 0.25em;
    padding-bottom: 0.5em;
    color: #172840;
    text-decoration: none;
    max-width: 5em;
    height: 18px !important;
  }

  .bottomContent {
    padding-top: 431px;
    padding-left: 20px;

    @media (max-width: 425px) {
      padding-top: 208px;
    }
  }

  .space {
    height: 20px;
    text-align: left;
    color: #172840;
    opacity: 1;
    vertical-align: middle;
    margin-top: 11px;
    margin-left: 10px;
  }

  .spaceHome,
  .spaceStepsToSuccess {
    font-size: 17px;
    height: 20px;
    text-align: left;
    color: #172840;
    opacity: 1;
    vertical-align: middle;
    margin-top: 11px;
    margin-left: 0;
  }

  .spaceStepsToSuccess {
    margin-top: 1.5em;
  }

  .iconStyle {
    width: 2em;
    margin-top: 10px;
    color: currentColor;

    @media (forced-colors: active) and (prefers-color-scheme: dark) {
      filter: invert(1);
    }
  }

  .iconPostitionLeft {
    position: relative;
    left: -4px;
  }

  .gridDivRight {
    height: 44px;

    @media (max-width: 280px) {
      width: 250px;
    }

    .cancelButton {
      float: right !important;
      padding-top: 0.5em;
      font-size: 1.5em;

      .navBarButton {
        padding: 0.25em;
        align-items: center;
        align-self: stretch;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        font-size: 1em;
        font-weight: 600;
        justify-content: center;
        line-height: normal;
        min-width: 30px;
        outline-offset: -0.125rem !important;
        text-decoration: none !important;
        white-space: nowrap;
      }
    }

    .searchButton {
      padding-top: 0.7em;
      cursor: pointer;
      font-size: 1.5em;
      text-align: right;
      padding-left: 1.2em;

      .searchIcon {
        color: #1906c0;
        transform: scale(0.8, 0.8);
      }
    }
  }
}

ol.rightSuccessNav {
  padding: 1.5em;
  margin-top: 0.35em;

  li.sideNavSuccessItem {
    height: 2.5em;
    position: relative;
    top: -1.9em;
    left: -0.5em;
    margin-top: 1.15em;

    a {
      position: relative;
      left: 0.2em;
      text-decoration: none;
      height: fit-content;
      &:hover {
        background-color: $white;
      }

      &.active {
        .Main_StepsToSuccess {
          position: absolute;
          top: -0.7em;
          left: -3.1em;
          height: 40px;
          width: 3px;
          background-image: linear-gradient(to right, #c73ecc, #c73ecc 10%);
        }
      }

      .spaceSuccessItem {
        color: #172840;
      }
    }
  }
}
