@import 'src/styles/variables.scss';

.searchParent {
  position: relative;

  .searchBoxContainer {
    font-size: 14px;
    font-weight: 400;
    box-shadow: none;
    margin: 0;
    padding: 1px 0 1px 4px;
    box-sizing: border-box;
    color: rgb(50, 49, 48);
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    border-radius: 2px;
    border: 1px solid rgb(0, 129, 255);
    height: 32px;
    width: 200px;

    @media only screen and (min-width: 320px) and (max-width: 425px) {
      width: 180px;
      float: left;
    }
  }

  .inputSearchBox {
    box-shadow: none;
    margin: 0;
    padding: 0 0 0.5px;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: inherit;
    font-family: inherit;
    font-size: inherit;
    color: rgb(50, 49, 48);
    flex: 1 1 0;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .iconContainerSearchBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    font-size: 16px;
    width: 32px;
    text-align: center;
    color: rgb(0, 120, 212);
    cursor: text;
    transition: width 0.167s ease 0s;
  }

  .iconSearchBox {
    display: inline-block;
    opacity: 1;
    transition: opacity 0.167s ease 0s;
  }

  .clearButton {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    cursor: pointer;
    flex-basis: 20px;
    flex-shrink: 0;
    padding: 0;
    margin: -1px 0;
    border: none;
    background: none;
  }

  .searchChild {
    margin-top: 0;
    position: absolute;
    top: 18px;
    right: 1px;
    min-width: max-content;
    min-height: max-content;
    z-index: 11;

    @media only screen and (min-width: 320px) and (max-width: 425px) {
      right: -7em;
      margin-top: 0;
      top: 3em;
    }

    label {
      display: none;
    }

    .items {
      padding-top: 1em;
      background-color: #fff;
      color: #000;

      @media only screen and (min-width: 320px) and (max-width: 425px) {
        margin-left: 10em;
        justify-content: flex-start;
        float: left;
        display: table-caption;
        width: 90%;
      }
    }

    .item {
      padding: 0 1em;
      height: 2em;

      &:hover,
      &:focus {
        cursor: pointer;
        font-style: italic;
        border: 2px dashed $black;
        border-radius: 0;
        background: none;
      }
    }

    .itemHidden {
      display: none;
    }
  }
}

.showTeach {
  .teachingBubbleHeader {
    font-weight: 600;
    margin: 0 1.7143em 1em 0;
    position: inherit;
  }
}
