@import '../../styles/variables.scss';

/* This file uses container queries, which are supported by all the
 * browsers that JupyterLab works with, but which stylelint cannot
 * validate.
 */
// Common style for pages
.container {
  /* stylelint-disable */
  container-type: inline-size;
  /* stylelint-enable */
  .ms-Link {
    color: $link-text;
  }

  .siteHeader {
    background-color: $dark-navy-blue;
    color: $white;

    a {
      text-decoration: none;
      &:hover,
      &:focus,
      &:active {
        text-decoration: none !important;
      }
    }

    .headerWrapper {
      align-content: center;
      display: flex;
      justify-content: space-between;
      max-width: 86%;
      margin: 0 auto;
      padding: 0;
      .pipe {
        &::before {
          content: '|';
          opacity: 0.41;
        }
      }
      .headerLink {
        align-items: center;
        display: flex;
        gap: 0.5em;
        padding: 1.5em 0;
        color: $white;
        &.active {
          border-bottom: 4px $white solid;
        }

        &:focus {
          outline: 1px solid #fff;
        }

        &:hover {
          cursor: pointer;
          color: $white;
        }

        .ms-Image {
          display: inline-block;
          vertical-align: middle;
        }

        .imageHeaderBarProfile {
          width: 20px;
          height: 20px;
        }

        .highContrastModeEnabled {
          display: none;
        }

        .highContrastModeDisabled {
          display: inline-block;
        }

        @media screen and (-ms-high-contrast: black-on-white) {
          .highContrastModeEnabled {
            display: inline-block;
          }

          .highContrastModeDisabled {
            display: none;
          }
        }

        .imageHeaderbarUser {
          margin-right: 1em;
        }

        .headerBar {
          letter-spacing: -0.12px;
          color: $white;
          display: flex;
          font-size: 16px;
          gap: 0.3em;

          @media screen and (-ms-high-contrast: black-on-white) {
            color: #0065b3;
          }
          @media screen and (-ms-high-contrast: white-on-black) {
            color: #0065b3;
          }

          .displayName {
            font-weight: 700;
          }
        }
      }

      .headerLeft {
        align-items: center;
        color: $white;
        display: flex;
        font-size: 18px;
        gap: 0.75em;

        .highContrastModeEnabled {
          display: none;
        }

        .highContrastModeDisabled {
          display: flex;
        }

        @media screen and (-ms-high-contrast: black-on-white) {
          .highContrastModeEnabled {
            display: flex;
          }

          .highContrastModeDisabled {
            display: none;
          }
        }

        .ms-Logo {
          .logo {
            margin: 3px 6px 0 0;
          }
        }

        .headerSiteWrapper {
          display: flex;
          align-items: center;
          gap: 0.5em;
        }

        .siteNameAcronym {
          display: none;
        }
      }

      .headerRight {
        align-items: center;
        color: $white;
        display: flex;
        font-size: 18px;
        gap: 0.75em;

        .navPersonaContainer {
          align-items: center;
          display: flex;
          gap: 0.75em;
          visibility: visible;

          .rightNavWrapper {
            display: flex;
            align-items: center;
            gap: 0.5em;
          }

          .calloutButton {
            background-color: transparent;
            border: none;
            padding: 0.3em 0;
          }
        }

        .hamburgerVisible {
          visibility: hidden;
          display: none;
        }
      }
    }
  }

  //Tab
  /* stylelint-disable */
  @container (min-width: 561px) and (max-width: 1640px) {
    /* stylelint-enable */
    .siteHeader {
      .headerWrapper {
        margin: 0 1.5em;
        max-width: 100%;

        .headerLink {
          padding: 0;
        }

        .headerLeft {
          .headerSiteWrapper {
            align-items: baseline;
            display: flex;
            gap: 0.5em;
            padding: 1em 0;

            /* stylelint-disable */
            @container (min-width: 561px) and (max-width: 1450px) {
              /* stylelint-enable */
              flex-direction: column;
              gap: 0;
            }
          }

          .pipe {
            display: flex;

            /* stylelint-disable */
            @container (min-width: 561px) and (max-width: 1450px) {
              /* stylelint-enable */
              display: none;
            }
          }
        }

        .headerRight {
          .navPersonaContainer {
            .rightNavWrapper {
              /* stylelint-disable */
              @container (min-width: 561px) and (max-width: 1340px) {
                /* stylelint-enable */
                visibility: hidden;
                display: none;
              }

              display: flex;
            }

            /* stylelint-disable */
            @container (min-width: 561px) and (max-width: 850px) {
              /* stylelint-enable */
              visibility: hidden;
              display: none;
            }
          }
          /* stylelint-disable */
          @container (min-width: 561px) and (max-width: 1340px) {
            /* stylelint-enable */
            .hamburgerVisible {
              visibility: visible;
              display: inline-block;
              width: 28px;
            }
          }
        }
      }
    }
  }

  //Mob
  /* stylelint-disable */
  @container (max-width: 560px) {
    /* stylelint-enable */
    .siteHeader {
      .headerWrapper {
        display: flex;
        justify-content: space-between;
        gap: 1em;
        margin: 0 1.5em;
        max-width: 100%;

        .headerLink {
          padding: 0;
        }

        .headerLeft {
          .headerSiteWrapper {
            display: none;
            visibility: hidden;
          }

          .pipe {
            display: none !important;
          }

          .siteNameAcronym {
            font-size: 1.25em;
            display: flex;
            padding: 1em 0;
            visibility: visible;
          }
        }

        .headerRight {
          .navPersonaContainer {
            visibility: hidden;
            display: none;
          }

          .hamburgerVisible {
            visibility: visible;
            display: inline-block;
            width: 28px;
          }
        }
      }
    }
  }
}
