@import '../../styles/variables.scss';

ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 14px !important;

  li {
    margin: 0;

    a {
      &:hover {
        background-color: $white;
      }

      .selectedItemMarker {
        @media (forced-colors: active) {
          border-style: ridge;
          max-height: 2.5em;
        }
      }

      &.active {
        background-color: $white;
        display: block;
        border-radius: 3px;
        width: $max-left-width;
        height: 44px;

        .Main_LearnYourOwn {
          height: 44px;
          width: 3px;
          background-image: linear-gradient(to right, #008575, #008575 10%);
        }

        .Main_ProductivityWorkshop {
          height: 44px;
          width: 3px;
          background-image: linear-gradient(to right, #008575, #008575 10%);
        }

        .Main_OneDayMSClass {
          height: 44px;
          width: 3px;
          background-image: linear-gradient(to right, $pink-dark, $pink-dark 10%);
        }

        .Main_MultidayMSClass {
          height: 44px;
          width: 3px;
          background-image: linear-gradient(to right, $pink, $pink 10%);
        }

        .Main_FindMoreClasses {
          height: 44px;
          width: 3px;
          background-image: linear-gradient(to right, $black, $black 10%);
        }

        .Main_GetMSCerts {
          height: 44px;
          width: 3px;
          background-image: linear-gradient(to right, $secondary-blue, $secondary-blue 10%);
        }

        .Main_GameLearning {
          height: 44px;
          width: 3px;
          background-image: linear-gradient(to right, #9b0f62, #9b0f62 10%);
        }

        .Main_StepsToSuccess {
          height: 44px;
          width: 3px;
          background-image: linear-gradient(to right, #c73ecc, #c73ecc 10%);
        }
      }
    }

    .stepsListItem {
      padding-top: 0.6em;
    }
  }
}

ol.sideNavSuccessList {
  li.sideNavSuccessItem {
    height: 4em;

    a {
      position: relative;
      left: 0.2em;
      text-decoration: none;
      &:hover {
        background-color: $white;
      }

      &.active {
        .Main_StepsToSuccess {
          position: absolute;
          top: -0.7em;
          left: -3.1em;
          height: 40px;
          width: 3px;
          background-image: linear-gradient(to right, #c73ecc, #c73ecc 10%);
        }
      }
    }

    .stepsListItem {
      .spaceSuccessItem {
        color: #172840;
        font-weight: 600;
        line-height: 2em;
      }
    }

    .selectedItemMarker {
      @media (forced-colors: active) {
        border-style: ridge;
        max-height: 2.5em;
      }
    }
  }
}

.home,
.stepsToSuccessHeader {
  color: $black;
  padding: 1em 1em 0 0;
  margin-left: 30px;
  font-size: 17px;
}

.home {
  margin-top: 0;
  padding-top: 0;
}

.rowArrange {
  background: #f3f6f8 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
}

.sideNav a {
  text-decoration: none;
  font-size: 15px;
  color: #0e0101;
  display: block;
  float: left;
  list-style-type: none;
}

.sideNav a:hover {
  color: #a09ef1;
}

.space {
  height: 20px;
  text-align: left;
  color: #172840;
  opacity: 1;
  vertical-align: middle;
  font-weight: 600;
  margin-top: 11px;
}

.iconStyle {
  width: 2em;
  margin-top: 10px;
  color: currentColor;

  @media (forced-colors: active) and (prefers-color-scheme: dark) {
    filter: invert(1);
  }
}

.gridDiv {
  height: 44px;
}

.sideNavSuccessNonSelected {
  background-color: $white;
  padding-bottom: 1em;
}

.sideNavSuccessSelected {
  background-color: #fff;
  padding-bottom: 0.05em;
  border-radius: 0 0 0 0 !important;
}

// Rightnav menu for mobile
.rightNavUl {
  width: 95%;
  margin: 0 auto;

  .spacehome,
  .stepsToSuccessHeader {
    font-size: 17px;
    font-weight: 600;
    margin-left: 0;
  }

  .stepsToSuccess {
    border-bottom: 2px solid #f1f1f1;
  }

  .sideNavSuccessSelected {
    background-color: none;

    .Main_StepsToSuccess {
      display: none;
    }
  }

  .sideNavSuccessNonSelected,
  .sideNavSuccessSelected {
    margin-bottom: 2em;
  }
}
